$grey-one: #2e2e2e;
$grey-two: #5d7380;
$grey-three: #829ea8;
$grey-four: #c2ccd0;
$grey-five: #E5E8E9;
$grey-six: #ebecee;
$grey-seven: #F1F1F3;

//$blue-one:#0047ab;
$blue-one:#0f52ba;

$body-bg:#ecf0f5;