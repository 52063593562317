@import "../../styles/colors";
@import "../../styles/z-index";

.blueBox{
  background-color: $blue-one;
  padding-bottom: 80px;
}

.hrCollapse{
  border-bottom: 1px dotted rgba($grey-seven, 0.4);
  width:44%;
}

.collapseButtonIcon{
  margin-top: -0.5rem;
}
.collapseButton{
  margin-top: -3rem;
  font-size: 90%;
  border:none !important;
  box-shadow: none !important;
  height: auto;
}

.line{
  background-color: white;
  width: 83%;
  height: 2px;
  display: inline-block;
  margin: 0px;
  position: absolute;
  top: 30.5%;
  margin-left: 6%;
  margin-right: 6%;
}
.zLevel2{
  z-index: 2;
}

.box{
 background-color: $grey-seven;
  position: relative;
  display: block;
  clear: both;
  right: 0;
  top: 0;
  min-height: 100rem;
  float: unset;
  margin: 0 auto;
  padding-top: 0;
  transition: all 0.7s ease-in;
}
.contentBody{
  margin-top: -50px;
}

.boxWithoutMinHeight{
 background-color: $grey-seven;
  position: relative;
  display: block;
  clear: both;
  right: 0;
  top: 0;
  float: unset;
  margin: 0 auto;
  padding-top: 0;
  transition: all 0.7s ease-in;
}

.cardStyle{
  box-shadow: 0 2px 10px -1px $grey-three;
  margin-bottom: 30px;
  transition: box-shadow 0.2s ease-in-out;
  background-color: white;
  min-height: 13rem;
}

.cardIcon{
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.contentBox{
  position: relative;
  display: block;
  padding-top: 25px;
  //margin-top: -30px;
}

.contentHeader{
  background: transparent;
  margin-top: -140px;
  margin-bottom: 30px;
}

.firstBreadcrumb{
  width: 25px;
}
.lineColorWhite::before{
  color: white !important;
}
