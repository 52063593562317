@import "../../styles/colors";
@import "../../styles/z-index";

.boxOffset{
margin-top: -40px;
}

.cardStyle{
  box-shadow: 0 2px 10px -1px $grey-three;
  margin-bottom: 30px;
  transition: box-shadow 0.2s ease-in-out;
  background-color: white;
}

.imgWrapper{
  margin-top: -40px;
  margin-bottom: -80px;
}

.imgCircle{
  z-index: 5;
  background: #4680ff;
  padding: 20px;
  border-radius: 50%;
  position: relative;
  height: 15vw;
  width: 15vw;
  overflow: hidden;
  float: left;
}

@media (max-width: 768px) {
  .imgWrapper{
    margin-top: 0px;
  }
  .imgCircle{
    width: 20vw;
    height: 20vw;
  }
}

@media (max-width: 576px) {
  .imgWrapper{
    margin-top: 0px;
  }
  .imgCircle{
    width: 25vw;
    height: 25vw;
  }
}