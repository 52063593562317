$primary: #007bff;
$strength-colors: (
  darkred,
  orangered,
  orange,
  yellowgreen,
  green
);
$strength-gap: 6px;

.mainContainer {
  position: relative;
  width: 100%;
}

.formContainer {
  width: 100%;
  // bottom: 100px;
}

legend.form-label {
  font-size: 1.5rem;
  color: desaturate(darken($primary, 10%), 60%);
}

.controlLabel {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 6px;
  color: desaturate(darken($primary, 10%), 80%);
}

.formControl {
  font-size: 20px;
}

.formHint {
  font-size: 0.6rem;
  line-height: 0.8;
  margin-top: 0px;
  color: #999;
  &.error {
    color: #c00;
    font-size: 0.75rem;
  }
}

.formInstructions {
  font-size: 0.6rem;
  line-height: 1.4;
  margin-top: 0px;
  color: #999;
  &.error {
    color: #c00;
    font-size: 0.8rem;
  }
}

.passwordCount {
  bottom: 13.5px;
  right: 10px;
  font-size: 1rem;
}

.strengthMeter {
  position: relative;
  height: 2px;
  background: #ddd;
  margin: 7px 0;
  border-radius: 2px;
  overflow: hidden;
  &:before,
  &:after {
    content: "";
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 $strength-gap 0;
    position: absolute;
    width: calc(20% + #{$strength-gap});
    z-index: 2;
  }
  &:before {
    left: calc(20% - #{($strength-gap / 2)});
  }
  &:after {
    right: calc(20% - #{($strength-gap / 2)});
  }
}

.strengthMeterFill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  // border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
  @for $i from 1 through 5 {
    &[data-strength="#{$i - 1}"] {
      width: (20% * $i);
      background: nth($strength-colors, $i);
    }
  }
}
