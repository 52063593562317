@import "../../styles/colors";
@import "../../styles/z-index";

.navbar{
  background-color: $blue-one;
}

.rightHeader{
width: auto;
}

button{
  background-color: transparent;
  border:none;
}