@import "../../styles/colors";
@import "../../styles/z-index";

.container {
  width: 100%;
  padding-right: 1rem;
  font-size: 0.8rem;
}

.imageContainer {
  position: relative;
}

.imageOverlay {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.wrapperRegister {
  height: 100vh;
  overflow: auto;
}

.register {
  background: -webkit-linear-gradient(left, #3931af, #00c6ff);
  margin-top: 3%;
  padding: 3%;
}

.registerLeft {
  text-align: center;
  color: #fff;
  margin-top: 4%;
}
.registerLeftInput{
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  width: 60%;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  margin-top: 30%;
  margin-bottom: 3%;
  cursor: pointer;
}
.registerRight{
  padding-left: 8% !important;
  padding-bottom: 5%;
  padding-top: 5%;
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
}
.registerLeftImg{
  margin-top: 15%;
  margin-bottom: 5%;
  width: 25%;
  -webkit-animation: mover 2s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
.requiredFieldStar{
  color: #f28383;
}
.selectCountryBarPosition{
  margin-top: 20px;
}
.imageLeftSideBlur{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 30px 0 30px -4px white inset;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}
.registerLeftP{
  font-weight: lighter;
  padding: 12%;
  margin-top: -9%;
  font-size: 1rem;
}
.register .register-form{
  padding: 10%;
  margin-top: 10%;
}
.btnRegister{
  width: 50%;

}
.register .nav-tabs{
  margin-top: 3%;
  border: none;
  background: #0062cc;
  border-radius: 1.5rem;
  width: 28%;
  float: right;
}
.register .nav-tabs .nav-link{
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover{
  border: none;
}
.register .nav-tabs .nav-link.active{
  width: 100px;
  color: #0062cc;
  border: 2px solid #0062cc;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.register-heading{
  text-align: center;
  margin-top: 8%;
  margin-bottom: -15%;
  color: #495057;
}
